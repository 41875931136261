import { ButtonLoader, Logo } from "../components"
import { useAuth } from "../context/authContext"
import { classNames } from "../utilities"
import { ExclamationCircleIcon, XCircleIcon } from "@heroicons/react/outline"
import * as Sentry from "@sentry/nextjs"
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import Link from "next/link"
import { useRouter } from "next/router"
import React, { useState } from "react"
import { useForm } from "react-hook-form"

dayjs.extend(utc)

interface FormData {
  email: string
  password: string
}

export default function Login() {
  const { loginWithPassword, checkSession } = useAuth()

  const router = useRouter()
  const [errorMessage, setErrorMessage] = useState("")

  React.useEffect(() => {
    checkSession()
      .then((session) => {
        if (session) {
          // console.log("session", session)
          if (session) {
            router.push("/")
          }
        }
      })
      .catch((error) => {
        console.log("error", error)
      })
  }, [])

  const [isSubmitting, setIsSubmitting] = useState(false)
  const {
    register,
    formState: { errors },
    resetField,
    handleSubmit,
  } = useForm<FormData>()

  const onSubmit = async ({ email, password }: FormData) => {
    setIsSubmitting(true)

    try {
      await loginWithPassword(email, password)
    } catch (error) {
      resetField("password")
      console.log(error)
      Sentry.captureException(error)
      setErrorMessage(error.description)

      setIsSubmitting(false)
      return
    }

    setIsSubmitting(false)
  }

  return (
    <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-lg">
        <div className="text-center">
          <Logo width={200} height={24} />
        </div>
        <h2 className="mt-6 text-center text-xl font-medium text-alt">
          Sign in to your partner account
        </h2>
        {/* <p className="mt-2 text-center text-sm text-gray-600">
          Don&rsquo;t have an account?{" "}
          <a
            href={`${process.env.NEXT_PUBLIC_MAIN_URL}/quote`}
            target="_blank"
            rel="noreferrer"
            className="font-medium text-primary hover:text-primaryHover"
          >
            Sign up
          </a>
        </p> */}
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          {errorMessage && (
            <div className="rounded-md bg-red-50 p-4 mb-4">
              <div className="flex">
                <div className="flex-shrink-0">
                  <XCircleIcon
                    className="h-5 w-5 text-red-400"
                    aria-hidden="true"
                  />
                </div>
                <div className="ml-3">
                  <h3 className="text-sm font-medium text-red-800">
                    There was an error with your submission
                  </h3>
                  <p className="mt-2 text-sm text-red-700">{errorMessage}</p>
                </div>
              </div>
            </div>
          )}
          <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Email address
              </label>
              <div className="mt-1 relative rounded-md shadow-sm">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  className={classNames(
                    errors.email
                      ? "focus:ring-red-500 focus:border-red-500"
                      : "focus:ring-primary focus:border-primary",
                    "block w-full py-2 px-4 placeholder-gray-500 border-gray-300 rounded-md"
                  )}
                  placeholder="Email"
                  {...register("email", { required: true })}
                />
              </div>
              {errors.email && (
                <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                  <ExclamationCircleIcon
                    className="h-5 w-5 text-red-500"
                    aria-hidden="true"
                  />
                </div>
              )}
            </div>

            <div>
              <label
                htmlFor="password"
                className="block text-sm font-medium text-gray-700"
              >
                Password
              </label>
              <div className="mt-1">
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  className={classNames(
                    errors.password
                      ? "focus:ring-red-500 focus:border-red-500"
                      : "focus:ring-primary focus:border-primary",
                    "block w-full py-2 px-4 placeholder-gray-500 border-gray-300 rounded-md"
                  )}
                  placeholder="Password"
                  {...register("password", { required: true })}
                />
              </div>
            </div>

            <div className="flex items-center justify-between">
              <Link
                href="/forgot-password"
                className="text-sm font-medium text-primary hover:text-primaryHover"
              >
                Forgot your password?
              </Link>
            </div>

            <div>
              <button type="submit" className="w-full btn-primary">
                <ButtonLoader loading={isSubmitting} loadingText="Signing in">
                  Sign in
                </ButtonLoader>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
